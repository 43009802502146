import React, { Component } from "react"
import logo from "./logo.svg"
import "./App.css"
import { Link } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

class App extends Component {
  render() {
    return (
      <div className="home-page-body">
                    
          <div className='home-page-top-bar'>

              <p className='home-page-logo'>
                  Hypergraf
              </p>
              
              
                  <Button
                      variant="outlined"
                      style={{
                          position: 'absolute',
                          top: '15px',
                          right: '30px',
                          width: '140px',
                          height: '40px',
                          border: '1px solid #0a1f44',
                          borderRadius: '20px',
                          fontSize: '20px',
                          color: '#0a1f44',
                          fontFamily: 'Avenir',
                          fontWeight: 400
                      }}>
                      Login
                  </Button>
              

          </div>

          <div className='home-page-howitworks-div'>
              <br /> <br /> <br /> <br /> <br /> <br /> <br />
              <Grid container justify="center" alignItems="center" spacing={3}>
                  <Grid item key={1} xs={6}>
                      <div className='hero-div-left'>
                          <div className='hero-div-left-primary-message'>
                              <mark className='half-highlight'>Stop wasting time</mark> staring at datagrids
                          </div>
                          <div className='hero-div-left-secondary-message'>
                              Hypergraf helps teams get from data to business insights 3x faster ⚡
                          </div>
                          <div className='hero-div-left-signup-button'>
                              <Link to="/signup">
                                  <p className='hero-div-left-signup-button-text'>
                                      Create an account
                                  </p>
                              </Link>
                          </div>
                      </div>
                  </Grid>
                  <Grid item key={2} xs={6}>
                      <div >
                          <img 
                              className='hero-div-right-image'
                              src={process.env.PUBLIC_URL + '/images/homepage-hero-image.svg'} 
                              draggable={false} />
                      </div>
                      
                  </Grid>


                  <Grid item key={2} xs={12}>
                      <br /> <br /> <br /> <br /> <br />
                      <div className='howitworks-div'>
                          <div className='hero-div-left-primary-message'>
                              <KeyboardArrowDownRoundedIcon
                                  style={{width: '50px'}} />
                          </div>
                      </div>
                  </Grid>

                  <Grid item key={2} xs={12}>
                      <br /> <br /> <br /> <br /> <br />
                      <div className='howitworks-div'>
                          <div className='hero-div-left-primary-message'>
                              <p style={{margin: 'auto'}}>
                                  How it works
                              </p>
                          </div>
                      </div>
                  </Grid>

                  <Grid item key={2} xs={6}>
                      <img
                          className='homepage-timeline-image' 
                          src={process.env.PUBLIC_URL + '/images/homepage_consolidate_data.svg'} />
                  </Grid>
                  <Grid item key={2} xs={6}>
                      <div className='timeline-div-message-box'>
                          <div className='hero-div-left-primary-message'>
                              Aggregate your data, whatever the source 🔗
                          </div>
                          <div className='hero-div-left-secondary-message'>
                              We support all form factors and all data sources - excel,
                              csv, text files, google drive, email, whatsapp. You name it!
                          </div>
                      </div>
                  </Grid>

                  <Grid item key={2} xs={6}>
                      <div className='timeline-div-message-box'>
                          <div className='hero-div-left-primary-message'>
                              Design workflows 🎨
                          </div>
                          <div className='hero-div-left-secondary-message'>
                              Our drag and drop canvas helps you create workflows for all use cases, within minutes!
                          </div>
                      </div>
                  </Grid>
                  <Grid item key={2} xs={6}>
                      <img
                          className='homepage-timeline-image' 
                          src={process.env.PUBLIC_URL + '/images/homepage_build_workflows.svg'} />
                  </Grid>

                  <Grid item key={2} xs={6}>
                      <img
                          className='homepage-timeline-image' 
                          src={process.env.PUBLIC_URL + '/images/homepage_visualize_trends.svg'} />
                  </Grid>
                  <Grid item key={2} xs={6}>
                      <div className='timeline-div-message-box'>
                          <div className='hero-div-left-primary-message'>
                              Be a trend spotter 😎
                          </div>
                          <div className='hero-div-left-secondary-message'>
                              Our visualization library helps you spot trends and jump to action faster than ever!
                          </div>
                      </div>
                  </Grid>

                  <Grid item key={2} xs={6}>
                      <div className='timeline-div-message-box'>
                          <div className='hero-div-left-primary-message'>
                              Automate and chill 🏖️
                          </div>
                          <div className='hero-div-left-secondary-message'>
                              Dont waste your time doing repetitive tasks. Our scheduler executes your workflows for you
                          </div>
                      </div>
                  </Grid>
                  <Grid item key={2} xs={6}>
                      <img
                          className='homepage-timeline-image' 
                          src={process.env.PUBLIC_URL + '/images/homepage_automate_and_relax.svg'} />
                  </Grid>

                  <Grid item key={2} xs={6}>
                      <img
                          className='homepage-timeline-image' 
                          src={process.env.PUBLIC_URL + '/images/homepage_reports_dashboards.svg'} />
                  </Grid>
                  <Grid item key={2} xs={6}>
                      <div className='timeline-div-message-box'>
                          <div className='hero-div-left-primary-message'>
                              Collaborate and share 📊
                          </div>
                          <div className='hero-div-left-secondary-message'>
                          Make sure you're all on the same page! Create and share reports and dashboards with your teammates
                          </div>
                      </div>
                  </Grid>

                  <Grid item key={2} xs={12}>
                      <br /> <br /> <br /> <br /> <br />
                      <div className='howitworks-div' style={{backgroundImage: 'url("https://uploads-ssl.webflow.com/5d7bfb33d27da2d97219c009/5f7b6adb52df7d1800042904_gradient-2.png")'}}>
                          <div className='hero-div-left-primary-message'>
                              <p style={{margin: 'auto'}}>
                                  What're you waiting for 🤷🏻‍♂️
                              </p>
                          </div>
                          <br /> <br /> <br />
                          <div className='bottom-div-left-signup-button'>
                              <Link to="/signup">
                                  <p className='bottom-div-left-signup-button-text'>
                                      Sign up and give us a shot 😊
                                  </p>
                              </Link>
                          </div>
                          <br /> <br /> <br /> <br /> <br /> <br />
                      </div>
                  </Grid>
              </Grid>
          </div>

      </div>
        
    )
  }
}

export default App
